
export const environment = {
   production: false,
   api: 'https://motor-uat-sa-dashboard-api.dchmsc.com/api/',
   SSOLogin: {
      clientId: 'ec209100-65de-4fc4-9235-309da97b8edc',
      authority: 'https://login.microsoftonline.com/0c354a30-f421-4d42-bd98-0d86e396d207',
      graph_endpoint: 'https://graph.microsoft.com/v1.0/me',
    }
  };
