import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationResult } from '@azure/msal-browser';
@Injectable({
    providedIn: 'root'
})
export class getTokenService {

    constructor(private msalService: MsalService) {}

    getTextSearchAccessToken() {
        let textAuth = '';
        if (!isEmpty(localStorage.getItem('accessToken'))) {
            let infoAccessTokenUser: any = JSON.parse(localStorage.getItem('accessToken') || '{}');
            textAuth = `&userId=${infoAccessTokenUser.uniqueId}&accessToken=${infoAccessTokenUser.accessToken}`;
        }
        return textAuth;
    }

    isAccessTokenValid() {
        let isValidAccessToken = true;
        const accessToken = localStorage.getItem('accessToken');

        if (!accessToken) {
            isValidAccessToken = false;
        } else {
            const authenticationResult: AuthenticationResult = JSON.parse(accessToken);
            isValidAccessToken = !authenticationResult.expiresOn
                ? false
                : new Date(authenticationResult.expiresOn).getTime() > new Date().getTime();
        }

        return isValidAccessToken;
    }

    exchangeAccessToken() {
        return this.msalService
            .instance.acquireTokenSilent({
                scopes: ["user.read"],
                account: this.msalService.instance.getAllAccounts()[0]
            });
    }

}
