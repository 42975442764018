import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { InteractionType } from '@azure/msal-browser';
import { environment } from '../../../environments/environment';
import { ShowNotifierService } from 'src/app/services/showNotifierService/showNotifierService.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

const GRAPH_ENDPOINT = environment.SSOLogin.graph_endpoint;
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  formGroup!: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private showNotifierService: ShowNotifierService,
    private router: Router
  ) {}

  ngOnInit() {
    localStorage.removeItem('notifications');
    localStorage.removeItem('search_vehicle');
    this.createForm();
  }

  createForm() {
    this.formGroup = this.formBuilder.group({
      'inputVIN': [null, Validators.required],
      'inputLicense': [null, Validators.required],
    });
  }

  onSubmit() {
    const {
      inputVIN,
      inputLicense
    } = this.formGroup.controls;

    if (inputVIN.invalid && inputLicense.invalid) {
      return this.showNotifierService.showAlerts('Cannot found VIN or License #', 404);
    }

    localStorage.setItem('search_vehicle', JSON.stringify(this.formGroup.value));
    this.router.navigate(['/page/dashboard']);
  }
}
