<div class="mb-3">
  <div class="row mb-3">
    <div class="col-lg-11"></div>
    <div class="col-lg-1">
      <button type="button" class="btn btn-info" (click)="openModal(contentTappings)">AMEND</button>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-lg-12">
      <div class="min-height-500" *ngIf="isLoadingModal">
        <div class="dark_overlay-modal">
          <div class="loader"></div>
        </div>
      </div>
      <div class="row mb-3" id="tagList" #div *ngIf="!isLoadingModal">
        <div class="col-lg-12" *ngFor="let tag of dataTagByCustomer; let i = index">
          <p><b>{{tag.Category}}</b> - {{tag.Item}}</p>
        </div>
      </div>
    </div>
  </div>
  
</div>


<!-- model -->
<ng-template #contentTappings let-modal>
  <div class="row mb-3">
    <div class="col-lg-12">
      <div class="min-height-500" *ngIf="isLoadingModalTaggingCategory">
        <div class="dark_overlay-modal">
          <div class="loader"></div>
        </div>
      </div>
      <div *ngIf="!isLoadingModalTaggingCategory">
        <div class="modal-header">
          <h4 class="modal-title">Category</h4>
          <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" >
          <div class="d-flex" >
            <ul ngbNav #navVp="ngbNav" class="nav-pills" orientation="vertical">
              <li *ngFor="let tag of dataTaggingCategory; let i = index" ngbNavItem="{{i}}" style="width: 25em;" [destroyOnHide]="false">
                <a ngbNavLink class="btn btn-rounded btn-outline-secondary">{{tag?.dch_name}}</a>
                <ng-template ngbNavContent>
                  <form>
                  <div><b>{{tag?.dch_name}}</b></div>
                  <br>
                  <div class="row mb-3">
                    <div class="col-auto" *ngFor="let tagByCategory of tag.listTag" style="padding-bottom: 0.5em;">
                      <div class="tagBtn">
                        <input [checked]="isSelected(tagByCategory?.dch_optionvalue)" class="{{tagByCategory?._dch_category_value}}" type="checkbox" value='{{tagByCategory?.dch_optionvalue}}' (change)="onChange($event, tagByCategory?._dch_category_value)"/>
                        <span>{{tagByCategory?.dch_tag}}</span>
                      </div>
                    </div>
                  </div>
                </form>
                </ng-template>
              </li>
            
            </ul>
            <div [ngbNavOutlet]="navVp" class="ml-4"></div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-info" (click)="addElement($event)">Add</button>
          <button type="button" class="btn btn-secondary" (click)="modal.dismiss('Cross click')">Cancel</button>
        </div>
      </div>
    </div>
  </div>

</ng-template>
