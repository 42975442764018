<div class="row">
  <div class="col-lg-1 col-md-0"></div>
  <div class="col-lg-10 col-md-12">
    <div class="card rounded-5">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="vehicle" [destroyOnHide]="false">
          <ngb-panel id="vehicle">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between rounded-5">
                <h3 class="m-0">Vehicle</h3>
                <button ngbPanelToggle class="btn btn-sm btn-info ml-2 rounded">Display On/Off</button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-vehicle [vehicleInfo]="vehicleInfo"></app-dashboard-vehicle>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="card rounded-5">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="service-Pattern" [destroyOnHide]="false">
          <ngb-panel id="service-Pattern">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="m-0">Service Pattern</h3>
                <button ngbPanelToggle class="btn btn-sm btn-info ml-2 rounded">Display On/Off</button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-service-pattern [vehicleInfo]="vehicleInfo"></app-dashboard-service-pattern>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="card rounded-5" *ngIf="vehicleInfo?.CUSTTYPE == 'I'">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="customer" [destroyOnHide]="false">
          <ngb-panel id="customer">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="m-0">Customer</h3>
                <button ngbPanelToggle class="btn btn-sm btn-info ml-2 rounded">Display On/Off</button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-customer [vehicleInfo]="vehicleInfo" [customerInfo]="customerInfo"></app-dashboard-customer>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="card rounded-5" *ngIf="vehicleInfo?.CUSTTYPE != 'I'">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="corporate-customer" [destroyOnHide]="false">
          <ngb-panel id="corporate-customer">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="m-0">Corporate Customer</h3>
                <button ngbPanelToggle class="btn btn-sm btn-info ml-2 rounded">Display On/Off</button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-corporate-customer [vehicleInfo]="vehicleInfo" [customerInfo]="customerInfo"></app-dashboard-corporate-customer>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="card rounded-5">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="taggings" [destroyOnHide]="false">
          <ngb-panel id="taggings">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="m-0">Taggings</h3>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-taggings *ngIf="vehicleInfo && customerInfo"  [vehicleInfo]="vehicleInfo" [customerInfo]="customerInfo"></app-dashboard-taggings>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="card rounded-5">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="rfm-score" [destroyOnHide]="false">
          <ngb-panel id="rfm-score">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="m-0">RFM Score</h3>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-rfm-score [vehicleInfo]="vehicleInfo"></app-dashboard-rfm-score>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="card rounded-5">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="service-suggestion" [destroyOnHide]="false">
          <ngb-panel id="service-suggestion">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="m-0">Service Suggestion</h3>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-service-suggestion *ngIf="vehicleInfo" [vehicleInfo]="vehicleInfo"></app-dashboard-service-suggestion>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="card rounded-5">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="footprint" [destroyOnHide]="false">
          <ngb-panel id="footprint">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="m-0">Footprint</h3>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-footprint *ngIf="vehicleInfo && customerInfo"  [vehicleInfo]="vehicleInfo" [customerInfo]="customerInfo"></app-dashboard-footprint>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="card rounded-5">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="quotation" [destroyOnHide]="false">
          <ngb-panel id="quotation">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="m-0">Quotation</h3>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-quotation *ngIf="vehicleInfo" [vehicleInfo]="vehicleInfo"></app-dashboard-quotation>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
    <div class="card rounded-5">
      <div class="card-body">
        <ngb-accordion #a="ngbAccordion" activeIds="jobs-table" [destroyOnHide]="false">
          <ngb-panel id="jobs-table">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="m-0">Jobs Table</h3>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <app-dashboard-jobs-table *ngIf="vehicleInfo" [vehicleInfo]="vehicleInfo"></app-dashboard-jobs-table>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
  <div class="col-lg-1 col-md-0"></div>
</div>
<div class="dark_overlay" *ngIf="isLoading">
  <div class="loader"></div>
</div>




<!-- model Complaint -->
<ng-template #contentComplaint let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Complaint Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTableComplaint" [columnName]="columnComplaint" [columnLimit]="10"></app-table-show-data>
  </div>
</ng-template>

<!-- custom column for complaint table -->
<ng-template #complaintRemarks let-row="row" let-rowIndex="rowIndex" let-columnValue="columnValue">
  <div container="body" class="text-overflow" ngbTooltip="{{row.COMPLAINTREMARKS}}">{{row.COMPLAINTREMARKS}}</div>
</ng-template>
<ng-template #remedyRemarks let-row="row" let-rowIndex="rowIndex" let-columnValue="columnValue">
  <div container="body" class="text-overflow" ngbTooltip="{{row.REMEDYREMARKS}}">{{row.REMEDYREMARKS}}</div>
</ng-template>