import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { getDataTable } from 'src/app/services/getDataDashboard/getDataTable.service';
import * as moment from 'moment';
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';

@Component({
  selector: 'app-dashboard-footprint',
  templateUrl: './dashboard-footprint.component.html',
  styleUrls: ['./dashboard-footprint.component.scss']
})
export class DashboardFootprintComponent implements OnInit {
  @Input() vehicleInfo: any;
  @Input() customerInfo: any;
  isLoadingModal: boolean = true;
  dataTableFootprint: any;
  columnFootprint: any;
  constructor(
    private getDataTable: getDataTable,
    private accessTokenService: getTokenService
  ) {}
  ngOnInit(): void {
    this.columnFootprint = [
      { name: 'Type', prop: 'type' },
      { name: 'Subject', prop: 'subject' },
      { name: 'Send Date', prop: 'sendDate' },
      { name: 'Destination', prop: 'destination' },
    ];
    this.getDataFootprint();
  }

  async getDataFootprint() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.getDataTable.getDataFootprint(String(this.customerInfo.contactid)).subscribe((response: any[]) => {
      let dataReturn: any[] = [];
      response.forEach((element: any) => {
        if (element.sendDate != null) {
          element.sendDate = `${moment(new Date(element.sendDate)).format("d-MMM-yyyy")}`;
        } else {
          element.sendDate = '';
        }
        dataReturn.push(element);
      });
      this.dataTableFootprint = dataReturn;
      this.isLoadingModal = false;
    }, (error: any) => {
      this.isLoadingModal = false;
    });
  }

}
