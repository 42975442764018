import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { getDataJobTableSection } from 'src/app/services/getDataDashboard/getDataJobTableSection.service';
import { getDataTable } from 'src/app/services/getDataDashboard/getDataTable.service';
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';

@Component({
  selector: 'app-dashboard-jobs-table',
  templateUrl: './dashboard-jobs-table.component.html',
  styleUrls: ['./dashboard-jobs-table.component.scss']
})
export class DashboardJobsTableComponent implements OnInit {
  @Input() vehicleInfo: any;
  @ViewChild('jobType', { static: true }) jobType!: TemplateRef<any>;
  @ViewChild('contentJobDetail') contentJobDetail!: TemplateRef<any>;
  isLoadingModal: boolean = true;
  isLoadingJobTypeYearModal: boolean = true;
  dataJobTableSection: any;
  dataJobByJobTypeYear: any;
  columnJobByJobTypeYear: any;
  columnName: any;
  jobTypeName: any;

  constructor(
    private getDataJobTableSection: getDataJobTableSection,
    private getDataTable: getDataTable,
    private modalService: NgbModal,
    private accessTokenService: getTokenService
  ) {}

  ngOnInit() {
    this.columnName = [];
    let currentYear = new Date().getFullYear();
    this.columnName.push(_.assign({ name: 'Job Type', prop: 'JOBTYPE', cellTemplate: this.jobType }));
    for (let index = 0; index < 10; index++) {
      this.columnName.push(_.assign({ name: currentYear, prop: currentYear, summaryFunc: (cells: any) => this.summaryJob(cells) }));
      currentYear--;
    }
    this.columnName.push(_.assign({ name: 'Total', prop: 'TOTAL' }));
    this.getDataJobTable();
    this.columnJobByJobTypeYear = [
      { name: 'R/O No.', prop: 'RONO' },
      { name: 'R/O Date', prop: 'RODATE' },
      { name: 'Job Type', prop: 'JOBTYPE' },
      { name: 'Job Code', prop: 'JOBCODE' },
      { name: 'Description', prop: 'JOBDSC' },
    ]
  }

  onActivate(event: any) {
    let value = String(event.value).match(/\d+/g);
    if (event.type == 'click' && Number(value) > 0) {
      this.dataJobByJobTypeYear = [];
      this.isLoadingJobTypeYearModal = true;
      this.jobTypeName = String(event.row.JOBTYPE);
      this.getDataTable.getRoByJobTypeYear(String(this.vehicleInfo.CHSNO), this.jobTypeName, String(event.column.prop)).subscribe((response: any[]) => {
        let dataReturn: any[] = [];
        response.forEach((element: any) => {
          element.JOBTYPE = this.jobTypeName;
          dataReturn.push(element);
        });
        this.dataJobByJobTypeYear = dataReturn;
        this.isLoadingJobTypeYearModal = false;
      }, (error: any) => {
        this.isLoadingJobTypeYearModal = false;
      });
      this.modalService.open(this.contentJobDetail, { size: 'xl', centered: true });
    }
  }

  summaryJob(cells: string[]) {
    let sum: number = 0;
    for (const cell of cells) {
      let matches = String(cell).match(/\d+/g);
      sum += Number(matches);
    }
    return sum;
  }

  async getDataJobTable() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.getDataJobTableSection.getDataJobTable(String(this.vehicleInfo.CHSNO)).subscribe((response: any[]) => {
      this.dataJobTableSection = this.addLinkToJobTable(response);
      this.isLoadingModal = false;
    }, (error: any) => {
      this.isLoadingModal = false;
    });
  }

  addLinkToJobTable(jobTables: any) {
    let result: any = [];
    for (const job of jobTables) {
      let currentYear = new Date().getFullYear();
      for (let index = 0; index < 10; index++) {
        if (job[currentYear] > 0) {
          job[currentYear] = `<a class="link-secondary">${job[currentYear]}</a>`
        }
        currentYear--;

      }
      result.push(job);
    }
    return result;
  }
}
