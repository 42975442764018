<div class="row mb-3">
  <div class="col-lg-12">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTableServiceSuggestion" [columnName]="columnServiceSuggestion"
      [columnLimit]="10"></app-table-show-data>
  </div>
</div>


<!-- custom column for Service Suggestion table -->
<ng-template #brochureURL let-row="row" let-rowIndex="rowIndex" let-columnValue="columnValue">
  <a *ngIf="row.BORCHUREURL" class="btn btn-info" target="_blank" href="{{row.BORCHUREURL}}">View</a>
</ng-template>
<ng-template #videoURL let-row="row" let-rowIndex="rowIndex" let-columnValue="columnValue">
  <a *ngIf="row.VIDEOURL" class="btn btn-info" target="_blank" href="{{row.VIDEOURL}}">View</a>
</ng-template>