export interface VehicleInfo {
    CUSTID:               string;
    CUSTTYPE:             string;
    LICNO:                string;
    CHSNO:                string;
    NCNO:                 string;
    MAKE:                 string;
    SVCMODEL:             string;
    MODELYEAR:            number;
    MFRYEAR:              number;
    SVCVEHCLASS:          string;
    DELIVERYDATE:         Date;
    MFRWRNEXPIRYDATE:     Date;
    MFRWRNMILEAGE:        number;
    EXTWRNEXPIRYDATE:     Date;
    EXTWRNMILEAGE:        number;
    CARAGE:               number;
    COATINGFIRSTAPPLY:    null;
    INSEXPDATE:           null;
    LASTROCONTACTPERSON1: string;
    LASTROCONTACTPERSON2: null;
    LASTROCONTACTPHONE1:  string;
    LASTROCONTACTPHONE2:  null;
    LASTROCONTACTFAX:     null;
    LASTROCONTACTEMAIL:   string;
    MEMBERCLASS:          string;
    VALIDPOINT:           number;
    CONTRACTSALESMAN:     string;
    LASTRODATE:           Date;
    AVGVISIT:             number;
    TOTALVISIT:           number;
    LASTVISITSVCCTR:      string;
    LASTVISITSA:          string;
    LASTMILEAGE:          number;
    LASTYEARMILEAGE:      number;
    AVGYEARMILEAGE:       number;
    AVGSPENDING:          number;
    LAST12MONTHSSPENDING: number;
    OFFICEPHONE:          string;
    PREVIOUSDCHCAROWNER:  string;
    CUSTOMERCATEGORY:     string;
    RECENCYSCORE:         number;
    FREQUENCYSCORE:       number;
    MONETARYSCORE:        number;
    FIRSTREGDATE:         Date;
    LASTCOMPLAINTSTATUS:  null;
    LASTCOMPLAINTDATE:    null;
    LASTCOMPLAINTNO:      null;
    MSSREMARKS:           null;
    N1:                   string;
    SIO:                  string;
    SMP:                  string;
    EMP:                  string;
    STP:                  string;
    PMCount:              number;
}


export interface OS_ROAndDataRO_JOB {
    JOBCODE: string;
    JOBTYPE: string;
    JOBDSC: string;
    PARTSTS: string;
    RONO: string;
    RODATE: string;
    STATUS: string;
}

export interface PartsStatus {
    RONO: string;
    RODATE: string;
    JOBCODE: string;
    JOBTYPE: string;
    JOBDSC: string;
    PARTSTS: string;
}

export interface UpcomingAppt {
    APPTNO: string;
    APPTDATE: string;
    APPTTIME: string;
    JOBCODE: string;
    APPTDATETIME: string;
}
