import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { getDataCustomerSection } from 'src/app/services/getDataDashboard/getDataCustomerSection.service';
import * as _ from "lodash";
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';

@Component({
  selector: 'app-dashboard-corporate-customer',
  templateUrl: './dashboard-corporate-customer.component.html',
  styleUrls: ['./dashboard-corporate-customer.component.scss']
})
export class DashboardCorporateCustomerComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private getDataCustomerSection: getDataCustomerSection,
    private accessTokenService: getTokenService
  ) {}
  
  chassisNo!: string;
  isLoadingModal!: boolean;
  @Input() vehicleInfo: any;
  @Input() customerInfo: any;
  @ViewChild('complaintRemarks', { static: true }) complaintRemarks!: TemplateRef<any>;
  @ViewChild('remedyRemarks', { static: true }) remedyRemarks!: TemplateRef<any>;
  dataTableVehicleDetail: any;
  columnVehicleDetail: any;
  dataTableOptInOutDetail: any;
  columnOptInOutDetail: any;
  dataTableComplaint: any;
  columnComplaint: any;
  complaintRemarkDetail = '';
  remedyRemarkDetail = '';

  ngOnInit(): void {
    this.columnVehicleDetail = [
      { name: 'Chasis No.', prop: 'dch_vin' },
      { name: 'Make', prop: 'dch_mssmake' },
      { name: 'Service Model', prop: 'dch_servicemodel' },
      { name: 'Service Model Year', prop: 'dch_servicemodelyear' },
      { name: 'License No.', prop: 'dch_licencenumber' },
    ];
    this.columnOptInOutDetail = [
      { name: 'Brand Code', prop: 'dch_brandcode' },
      { name: 'Fax Opt Out', prop: 'dch_faxoptout' },
      { name: 'SMS Opt Out', prop: 'dch_smsoptout' },
      { name: 'DM Opt Out', prop: 'dch_dmoptout' },
      { name: 'Call Opt Out', prop: 'dch_calloptout' },
      { name: 'Email Opt Out', prop: 'dch_emailoptout' },
    ];
    this.columnComplaint = [
      { name: 'Complaint No.', prop: 'COMPLAINTCODE' },
      { name: 'Received Date', prop: 'RECEIVEDDATE' },
      { name: 'Closed Date', prop: 'CLOSEDDATE' },
      { name: 'Complaint Result Date', prop: 'COMPLAINTRESULTDATE' },
      { name: 'Severity', prop: 'SEVERITY' },
      { name: 'Complaint Source', prop: 'COMPLAINTSOURCE' },
      { name: 'Complaint Remarks', prop: 'COMPLAINTREMARKS', cellTemplate: this.complaintRemarks },
      { name: 'Remedy Remarks', prop: 'REMEDYREMARKS', cellTemplate: this.remedyRemarks },
      { name: 'Result Remarks', prop: 'RESULTREMARKS' },
      { name: 'Complaint Code', prop: 'COMPLAINTCODE' },
      { name: 'Complaint Descrption', prop: 'COMPLAINTDSCC' },
      { name: 'Remedy Code', prop: 'REMEDYCODE' },
      { name: 'Remedy Descrption', prop: 'REMEDYDSC' },
    ];
  }

  openModal(content: string, nameModal: string, modalData?: any) {
    this.chassisNo = this.vehicleInfo.CHSNO;
    this.isLoadingModal = false;
    let modalSize = 'xl';

    switch (nameModal) {
      case 'contentVehicleDetail':
        if (_.isEmpty(this.dataTableVehicleDetail)) {
          this.isLoadingModal = true;
          this.getDataVehicleDetail();
        }
        break;
      case 'contentOptInOutDetail':
        if (_.isEmpty(this.dataTableOptInOutDetail)) {
          this.isLoadingModal = true;
          this.getDataOptInOutDetail();
        }
        break;
      case 'contentComplaint':
        if (_.isEmpty(this.dataTableComplaint)) {
          this.isLoadingModal = true;
          this.getDataComplaint();
        }
        break;
      case 'complaintRemarkDetailModal':
        modalSize = 'system-lg';
        this.complaintRemarkDetail = modalData;
        break;
      case 'remedyRemarkDetailModal':
        modalSize = 'system-lg';
        this.remedyRemarkDetail = modalData;
        break;
      default:
        break;
    }

    this.modalService.open(content, { size: modalSize, centered: true });
  }

  async getDataVehicleDetail() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.getDataCustomerSection.getDataVehicleDetail(this.customerInfo.contactid).subscribe((response: any) => {
      this.dataTableVehicleDetail = response?.value;
      this.isLoadingModal = false;
    }, (error: any) => { });
  }

  async getDataOptInOutDetail() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.getDataCustomerSection.getDataOptInOut(this.customerInfo.contactid).subscribe((response: any) => {
      this.dataTableOptInOutDetail = response?.value;
      this.dataTableOptInOutDetail.forEach((element: any) => {
        var res = Object.keys(element).filter(key => element[key]);
        res.forEach((key: any) => {
          if (typeof element[key] == 'boolean') {
            element[key] = element[key] ? "Yes" : "No";
          };
        });
      });
      this.isLoadingModal = false;
    }, (error: any) => { });
  }

  async getDataComplaint() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.getDataCustomerSection.getDataComplaint(this.chassisNo).subscribe((response: any) => {
      this.dataTableComplaint = response;
      this.isLoadingModal = false;
    }, (error: any) => { });
  }

}
