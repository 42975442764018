import { Injectable } from '@angular/core';
import { NotifierService } from 'angular-notifier';

@Injectable({
    providedIn: 'root'
})
export class ShowNotifierService {
    private notifier: NotifierService;
    constructor(notifier: NotifierService) { this.notifier = notifier; }

    showAlerts(content: string, statusCode: number, typeAlerts: string = '') {
        switch (statusCode) {
            case 200:
            case 202:
                typeAlerts = 'success';
                break;
            case 400:
            case 404:
            case 500:
                typeAlerts = 'error';
                break;
            default:
                break;
        }
        this.notifier.notify(typeAlerts, content);
    }
    
    hideAllNotifications(): void {
		this.notifier.hideAll();
	}

}
