import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';

@Injectable({
    providedIn: 'root'
})
export class getDataCustomerSection {

    constructor(private http: HttpClient, private getTokenService: getTokenService) { }
    getDataDataCustomer(textSearch: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `contact_number=${textSearch}${textAuth}`;
        const url = environment.api + `customer-corporatecustomer-section/getDataCustomer?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataVehicleDetail(textSearch: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `vehicle_number=${textSearch}${textAuth}`;
        const url = environment.api + `customer-corporatecustomer-section/getDataVehicle?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataOptInOut(textSearch: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `account_value=${textSearch}${textAuth}`;
        const url = environment.api + `customer-corporatecustomer-section/getDataOptInOut?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataComplaint(textSearch: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${textSearch}${textAuth}`;
        const url = environment.api + `customer-corporatecustomer-section/getDataComplaint?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

}
