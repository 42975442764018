<div class="row mb-3">
  <div class="col-lg-12">
    <div class="min-height-500" *ngIf="isLoadingModalQuotation">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModalQuotation" [dataTable]="dataTableQuotation" [columnName]="columnQuotation"
      [columnLimit]="10"></app-table-show-data>
  </div>
</div>
<hr>
<div class="row mb-3">
  <div class="col-lg-12">
    <div class="min-height-500" *ngIf="isLoadingModalRORemark">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModalRORemark" [dataTable]="dataTableRORemark" [columnName]="columnRORemark"
      [columnLimit]="10"></app-table-show-data>
  </div>
</div>
