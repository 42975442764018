import { Component, Input, OnInit } from '@angular/core';
import { getDataTable } from 'src/app/services/getDataDashboard/getDataTable.service';
import * as moment from 'moment';
@Component({
  selector: 'app-dashboard-quotation',
  templateUrl: './dashboard-quotation.component.html',
  styleUrls: ['./dashboard-quotation.component.scss']
})
export class DashboardQuotationComponent implements OnInit {
  @Input() vehicleInfo: any;
  isLoadingModalQuotation: boolean = true;
  isLoadingModalRORemark: boolean = true;
  dataTableQuotation: any;
  dataTableRORemark: any;
  columnQuotation: any;
  columnRORemark: any;

  constructor(private getDataTable: getDataTable,) { }

  ngOnInit(): void {
    this.columnQuotation = [
      { name: 'Quotation No.', prop: 'QUOTATIONNO' },
      { name: 'Quotation Date', prop: 'QUOTATIONDATE' },
      { name: 'Job Code', prop: 'JOBCODE' },
      { name: 'Total Quotation Amount', prop: 'TOTALAMT' },
    ];
    this.columnRORemark = [
      { name: 'R/O No.', prop: 'RONO' },
      { name: 'R/O Remarks', prop: 'ROREMARKS' },
      { name: 'R/O Date', prop: 'RODATE' },
    ];
    this.getDataQuotation();
    this.getDataRORemark();
  }

  getDataQuotation() {
    this.getDataTable.getDataQuotation(String(this.vehicleInfo.CHSNO)).subscribe((response: any[]) => {
      let dataReturn: any[] = [];
      response.forEach((element: any) => {
        if (element.QUOTATIONDATE != null) {
          element.QUOTATIONDATE = `${moment(new Date(element.QUOTATIONDATE)).format("d-MMM-yyyy")}`;
        } else {
          element.QUOTATIONDATE = '';
        }
        dataReturn.push(element);
      });
      this.dataTableQuotation = dataReturn;
      this.isLoadingModalQuotation = false;
    }, (error: any) => {
      this.isLoadingModalQuotation = false;
    });
  }

  getDataRORemark() {
    this.getDataTable.getDataRORemark(String(this.vehicleInfo.CHSNO)).subscribe((response: any[]) => {
      let dataReturn: any[] = [];
      response.forEach((element: any) => {
        if (element.RODATE != null) {
          element.RODATE = `${moment(new Date(element.RODATE)).format("d-MMM-yyyy")}`;
        } else {
          element.RODATE = '';
        }
        dataReturn.push(element);
      });
      this.dataTableRORemark = dataReturn;
      this.isLoadingModalRORemark = false;
    }, (error: any) => {
      this.isLoadingModalRORemark = false;
    });
  }
}
