import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { fromEvent } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-table-show-data',
  templateUrl: './table-show-data.component.html',
  styleUrls: ['./table-show-data.component.scss']
})
export class TableShowDataComponent implements OnInit {
  @Input() dataTable: any;
  @Input() columnName: any;
  @Input() columnLimit: any;
  @ViewChild('search', { static: false }) search: any;
  public temp: Array<object> = [];
  public rows: Array<object> = [];
  public columns: Array<object> = [];
  constructor() { }

  ngOnInit() {
    this.columns = this.columnName;
    this.rows = this.temp = this.dataTable;
  }

  ngAfterViewInit(): void {
    fromEvent(this.search.nativeElement, 'keydown')
      .pipe(debounceTime(550), map((x: any) => x['target']['value']))
      .subscribe(value => {
        this.updateFilter(value);
      });
  }

  updateFilter(val: any) {
    const value = val.toString().toLowerCase().trim();
    const count = this.columns.length;
    const keys = Object.keys(this.temp[0]);
    this.rows = this.temp.filter((item: any) => {
      for (let i = 0; i < count; i++) {
        if ((item[keys[i]] && item[keys[i]].toString().toLowerCase().indexOf(value) !== -1) || !value) {
          return true;
        }
      }
    });
  }

  findAll() {
    this.temp = this.dataTable;
    this.rows = [...this.temp];
  }
}
