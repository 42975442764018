<div class="row mb-3">
  <div class="col-xl-3 col-lg-4 col-md-4 form-group row">
    <label class="col-xl-3 col-lg-3 col-md-5 control-label col-form-label">Category:</label>
    <div class="col-auto">
      <input type="text" class="form-control" value="{{vehicleInfo?.CUSTOMERCATEGORY}}" disabled>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 form-group row">
    <label class="col-xl-3 col-lg-3 col-md-5 control-label col-form-label">Recency:</label>
    <div class="col-auto" ngbTooltip="{{vehicleInfo?.RECENCYSCORE/30 | number}} months">
      <input type="text" class="form-control" value="{{vehicleInfo?.RECENCYSCORE | number:'1.0-0'}} days" disabled>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 form-group row">
    <label class="col-xl-4 col-lg-4 col-md-5 control-label col-form-label">Frequency:</label>
    <div class="col-auto">
      <input type="text" class="form-control" value="{{vehicleInfo?.FREQUENCYSCORE | number:'1.0-0'}}/year" disabled>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-4 form-group row">
    <label class="col-xl-3 col-lg-3 col-md-5 control-label col-form-label">Monetory:</label>
    <div class="col-auto">
      <input type="text" class="form-control" value="${{vehicleInfo?.MONETARYSCORE | number:'1.0-0'}}/year" disabled>
    </div>
  </div>
</div>
