import { Component, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Search } from 'src/app/model/search';
import { VehicleInfo } from 'src/app/model/vehicle-section';
import { getDataVehicleSection } from 'src/app/services/getDataDashboard/getDataVehicleSection.service';
import { ShowNotifierService } from 'src/app/services/showNotifierService/showNotifierService.service';
import { getDataCustomerSection } from 'src/app/services/getDataDashboard/getDataCustomerSection.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  isLoading: boolean = false;
  vehicleInfo: VehicleInfo | undefined;
  customerInfo: any;
  @ViewChild('complaintRemarks', { static: true }) complaintRemarks!: TemplateRef<any>;
  @ViewChild('remedyRemarks', { static: true }) remedyRemarks!: TemplateRef<any>;
  @ViewChild('contentComplaint') contentComplaint!: TemplateRef<any>;
  constructor(
    private router: Router,
    private getDataVehicleSection: getDataVehicleSection,
    private getDataCustomerSection: getDataCustomerSection,
    private showNotifierService: ShowNotifierService,
    private activatedRoute: ActivatedRoute,
    private modalService: NgbModal,
    private accessTokenService: getTokenService
  ) { }
  textSearch!: Search;
  notifications: any = [];
  columnComplaint: any;
  dataTableComplaint: any = [];
  private readonly _destroying$ = new Subject<void>();
  @HostListener('document:click', ['$event']) onClick(event: any) {
    if (event.target.id == 'complaint') {
      this.modalService.open(this.contentComplaint, { size: 'xl', centered: true });
    }
  }
 ngOnInit(): void {
    localStorage.removeItem('notifications');
    if (this.getTextSearchVehicle() == '{}' || this.getTextSearchVehicle() == null) {
      this.router.navigate(['/page/search']);
    } else {
      this.isLoading = true;
      this.getVehicleInfo();
    }
    this.columnComplaint = [
      { name: 'Complaint No.', prop: 'COMPLAINTCODE' },
      { name: 'Received Date', prop: 'RECEIVEDDATE' },
      { name: 'Closed Date', prop: 'CLOSEDDATE' },
      { name: 'Complaint Result Date', prop: 'COMPLAINTRESULTDATE' },
      { name: 'Severity', prop: 'SEVERITY' },
      { name: 'Complaint Source', prop: 'COMPLAINTSOURCE' },
      { name: 'Complaint Remarks', prop: 'COMPLAINTREMARKS', cellTemplate: this.complaintRemarks },
      { name: 'Remedy Remarks', prop: 'REMEDYREMARKS', cellTemplate: this.remedyRemarks },
      { name: 'Result Remarks', prop: 'RESULTREMARKS' },
      { name: 'Complaint Code', prop: 'COMPLAINTCODE' },
      { name: 'Complaint Descrption', prop: 'COMPLAINTDSCC' },
      { name: 'Remedy Code', prop: 'REMEDYCODE' },
      { name: 'Remedy Descrption', prop: 'REMEDYDSC' },
    ];
  }

  ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}

  getTextSearchVehicle() {
    return JSON.parse(this.getTextSearchInParam() || '{}');
  }

  getTextSearchInParam() {
    let data: any;
    if (_.isEmpty(this.activatedRoute.snapshot.queryParams)) {
      return localStorage.getItem('search_vehicle') || '{}';
    } else {
      data = {
        "inputVIN": this.activatedRoute.snapshot.queryParams.ChassisNo,
        "inputLicense": null,
        "serviceCenter": this.activatedRoute.snapshot.queryParams.ServiceCenterCode,
      }
      return JSON.stringify(data);
    }
  }

  async getVehicleInfo() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.textSearch = this.getTextSearchVehicle();

    this.getDataVehicleSection
      .getVehicleInfo(this.textSearch)
      .subscribe((response: VehicleInfo) => {
        this.vehicleInfo = response;
        this.getCustomerInfo(this.vehicleInfo.CUSTID);
        this.isLoading = false;
      }, (error: any) => {
        this.router.navigate(['/page/search']);
        let messages = error.error.errors
        messages.forEach((item: any) => {
          this.showNotifierService.showAlerts(item.msg, error.status)
        });
      });
  }

  getCustomerInfo(customerId: string) {
    this.getDataCustomerSection.getDataDataCustomer(customerId).subscribe((response: any) => {
      this.customerInfo = response;
      this.getNotify();
    }, (error: any) => {
      let messages = error.error.errors
      messages.forEach((item: any) => {
        this.showNotifierService.showAlerts(item.msg, error.status)
      });
    });
  }

  getNotify() {
    let nextDay = new Date().setDate(new Date().getDate() + 15);
    let oldDate = new Date().setDate(new Date().getDate() - 15);
    this.getBirthday(nextDay, oldDate);
    this.getVehicleBirthdayMonth(nextDay, oldDate);
    this.getComplaintReceive(nextDay, oldDate);


  }

  getBirthday(nextDay: number, oldDate: number) {
    let customerBirthdate = this.customerInfo?.birthdate;
    if (customerBirthdate) {
      let birthdate = parseInt(moment(`${moment(customerBirthdate).format("MM")}/${moment(customerBirthdate).format("DD")}/${moment().format("YYYY")}`).format("x"));
      if (oldDate < birthdate && birthdate < nextDay) {
        this.notifications.push({ title: 'Birthday', subject: `The customer’s birthday is ${moment(`${moment(customerBirthdate).format("MM")}/${moment(customerBirthdate).format("DD")}/${moment().format("YYYY")}`).fromNow()}` });
      }
    }
  }

  getVehicleBirthdayMonth(nextDay: number, oldDate: number) {
    if (this.vehicleInfo?.DELIVERYDATE) {
      let deliveryDate = new Date(this.vehicleInfo.DELIVERYDATE).getTime();
      if (oldDate < deliveryDate && deliveryDate < nextDay) {
        this.notifications.push({ title: 'Vehicle Birthday Month', subject: `The vehicle is at its ${moment(this.vehicleInfo.DELIVERYDATE).fromNow(true)} ` });
      }
    }
  }

  getComplaintReceive(nextDay: number, oldDate: number) {
    if (this.vehicleInfo?.CHSNO) {
      this.getDataCustomerSection.getDataComplaint(this.vehicleInfo.CHSNO).subscribe((response: any) => {
        let dataReturn: any[] = [];
        response.forEach((element: any) => {
          if (element.RECEIVEDDATE != null) {
            element.RECEIVEDDATE = `${moment(new Date(element.RECEIVEDDATE)).format("DD-MMM-yyyy")}`;
          } else {
            element.RECEIVEDDATE = '';
          }
          dataReturn.push(element);
        });
        this.dataTableComplaint = dataReturn;
        for (const item of response) {
          let receiveDate = new Date(item.RECEIVEDDATE).getTime();
          if (oldDate < receiveDate && receiveDate < nextDay) {
            this.notifications.push({ title: 'Complaint ', subject: `We received this customer’s complaint recently !!! <a href="javascript:void(0)" class="span-a" id="complaint">View</a>` });
            break;
          }
        }
        localStorage.setItem('notifications', JSON.stringify(this.notifications));
      }, (error: any) => { });
    }
  }

}
