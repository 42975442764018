<div id="jobs-table-section" class="collapse show">
  <div class="row mb-3">
    <div class="col-lg-12">
      <div class="min-height-500" *ngIf="isLoadingModal">
        <div class="dark_overlay-modal">
          <div class="loader"></div>
        </div>
      </div>
      <ngx-datatable *ngIf="!isLoadingModal" #table class='material striped' [summaryRow]="true" [summaryHeight]="54" [summaryPosition]="'bottom'" [columnMode]="'force'" [headerHeight]="50" [footerHeight]="0"
        [rowHeight]="'auto'"[rows]='dataJobTableSection' [columns]='columnName' (activate)="onActivate($event)">
        <ngx-datatable-footer></ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</div>


<!-- custom column for job table -->
<ng-template #jobType let-row="row" let-rowIndex="rowIndex" let-columnValue="columnValue">
  <div container="body" class="text-overflow" ngbTooltip="{{row.DESCRIPTION}}">{{row.JOBTYPE}}</div>
</ng-template>

<!-- model Job Detail -->
<ng-template #contentJobDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{jobTypeName.toUpperCase()}} Job Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="min-height-500" *ngIf="isLoadingJobTypeYearModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingJobTypeYearModal" [dataTable]="dataJobByJobTypeYear" [columnName]="columnJobByJobTypeYear"
      [columnLimit]="10"></app-table-show-data>
  </div>
</ng-template>
