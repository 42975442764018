<div class="row mb-3">
  <div class="col-lg-12">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTableFootprint" [columnName]="columnFootprint"
      [columnLimit]="10"></app-table-show-data>
  </div>
</div>
