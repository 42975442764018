<div id="vehicle-section" class="collapse show">
  <div class="row mb-3 g-1">
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-sm-3 control-label col-form-label">LIC#:</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" value="{{vehicleInfo?.LICNO}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-sm-3 control-label col-form-label">CHS#:</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" value="{{vehicleInfo?.CHSNO}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row ">
      <label class="col-sm-3 control-label col-form-label">NC#:</label>
      <div class="col-sm-8">
        <input type="text" class="form-control" value="{{vehicleInfo?.NCNO}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row ">
        <div class="col-sm-10">
          <span placement="bottom" ngbTooltip="Make, Car Model, Service Model, Model Year, Manual Year">
            <input type="text" class="form-control"
              value="{{vehicleInfo?.MAKE}} {{vehicleInfo?.SVCMODEL}} {{vehicleInfo?.MODELYEAR}} {{vehicleInfo?.MFRYEAR}}"
              disabled>
          </span>
        </div>
        <div class="col-sm-2 p-0">
          <input type="text" class="form-control" value="{{vehicleInfo?.SVCVEHCLASS}}" disabled>
        </div>
    </div>
  </div>
  
  <div class="row mb-3 g-1">
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-sm-5 control-label col-form-label">Delivery Date:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.DELIVERYDATE | date:'d-MMM-yyyy'}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row ">
      <label class="col-sm-5 control-label col-form-label">Mfg WA expiry:</label>
      <div class="col-auto" ngbTooltip="{{vehicleInfo?.MFRWRNEXPIRYDATE | date:'d-MMM-yyyy'}}, {{vehicleInfo?.MFRWRNMILEAGE}}KM">
        <input type="text" class="form-control text-danger" value="{{vehicleInfo?.MFRWRNEXPIRYDATE | date:'d-MMM-yyyy'}}, {{vehicleInfo?.MFRWRNMILEAGE}}KM" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row ">
      <label class="col-sm-5 control-label col-form-label">EW expiry:</label>
      <div class="col-auto" ngbTooltip="{{vehicleInfo?.EXTWRNEXPIRYDATE | date:'d-MMM-yyyy'}}, {{vehicleInfo?.EXTWRNMILEAGE}}KM">
        <input type="text" class="form-control text-danger" value="{{vehicleInfo?.EXTWRNEXPIRYDATE | date:'d-MMM-yyyy'}}, {{vehicleInfo?.EXTWRNMILEAGE}}KM" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row ">
      <label class="col-sm-5 control-label col-form-label">PM Count:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.PMCount}}" disabled>
      </div>
    </div>
  </div>

  <div class="row mb-3 g-1">
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-sm-5 control-label col-form-label">Car age:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.CARAGE}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <!-- <label class="col-sm-5 control-label col-form-label">&nbsp;</label> -->
      <div class="col-auto button-end">
        <div class="button-group">
          <button type="button" class="btn btn-secondary"
            (click)="openModal(contentOutstanding, 'contentOutstanding')">O/S RO</button>
          <button type="button" class="btn btn-secondary"
            (click)="openModal(contentAppointment, 'contentAppointment')">Appt.</button>
          <button type="button" class="btn btn-secondary"
            (click)="openModal(contentPartsStatus, 'contentPartsStatus')">Part Sys.</button>
        </div>
      </div>
    
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-sm-5 control-label col-form-label">Sales by:</label>
      <div class="col-auto" ngbTooltip="{{vehicleInfo?.CONTRACTSALESMAN}}, {{vehicleInfo?.OFFICEPHONE}}">
        <input type="text" class="form-control" value="{{vehicleInfo?.CONTRACTSALESMAN}}, {{vehicleInfo?.OFFICEPHONE}}" disabled>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-6 col-lg-6 col-md-6 control-label col-form-label">Coating first applied:</label>
      <div class="col-auto" ngbTooltip="{{vehicleInfo?.COATINGFIRSTAPPLY | date:'d-MMM-yyyy'}}">
        <input type="text" class="form-control" value="{{vehicleInfo?.COATINGFIRSTAPPLY | date:'d-MMM-yyyy'}}" disabled>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-6 col-lg-6 col-md-6 control-label col-form-label">DCH Insurance expiry:</label>
      <div class="col-auto" ngbTooltip="{{vehicleInfo?.INSEXPDATE | date:'d-MMM-yyyy'}}">
        <input type="text" class="form-control" value="{{vehicleInfo?.INSEXPDATE | date:'d-MMM-yyyy'}}" disabled>
      </div>
    </div>

   
  </div>
  <div class="row mb-3">
    <div class="col-lg-12">
      <div class="form-floating">
        <textarea style="min-height: 150px;" class="form-control" id="floatingTextarea"
          disabled>{{vehicleInfo?.MSSREMARKS}}</textarea>
        <label for="floatingTextarea">Vehicle Remark</label>
      </div>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-auto">
      <label for="last_date" class="col-form-label">Remaining service:</label>
    </div>
    <div class="col-lg-12">
      <input type="text" class="form-control"
        value="{{displayRemainingServices()}}"
        disabled>
    </div>
  </div>
</div>
<div class="dark_overlay" *ngIf="isLoading">
  <div class="loader"></div>
</div>
<!-- model O/S RO -->
<ng-template #contentOutstanding let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Outstanding R/O Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTableOutstanding" [columnName]="columnOutstanding"
      [columnLimit]="10"></app-table-show-data>
  </div>
</ng-template>

<!-- model Appt. -->
<ng-template #contentAppointment let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Appointment Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTableAppointment" [columnName]="columnAppointment"
      [columnLimit]="10"></app-table-show-data>
  </div>
</ng-template>

<!-- model Part Sys. -->
<ng-template #contentPartsStatus let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Parts Status Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTablePartsStatus" [columnName]="columnPartsStatus"
      [columnLimit]="10"></app-table-show-data>
  </div>
</ng-template>
