import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';


@Injectable({
    providedIn: 'root'
})
export class getDataTable {

    constructor(private http: HttpClient, private getTokenService: getTokenService) { }

    getRoByJobTypeYear(pi_chassis_no: String, pi_job_type: String, year: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${pi_chassis_no}&pi_job_type=${pi_job_type}&year=${year}${textAuth}`;
        const url = environment.api + `get-data-table/getRoByJobTypeYear?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataServiceSuggestion(pi_chassis_no: String, pi_sctr_code: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${pi_chassis_no}&pi_sctr_code=${pi_sctr_code}${textAuth}`;
        const url = environment.api + `get-data-table/getDataServiceSuggestion?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataFootprint(contactId: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `contactId=${contactId}${textAuth}`;
        const url = environment.api + `get-data-footprint/getDataFootprint?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataQuotation(pi_chassis_no: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${pi_chassis_no}${textAuth}`;
        const url = environment.api + `get-data-quotation/getDataQuotation?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataRORemark(pi_chassis_no: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${pi_chassis_no}${textAuth}`;
        const url = environment.api + `get-data-quotation/getDataRORemark?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataTaggingCategory(): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const url = environment.api + `get-data-tagging/getDataTaggingCategory?${textAuth}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataTagByCategory(categoryId: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `categoryId=${categoryId}`;
        const url = environment.api + `get-data-tagging/getDataTagByCategory?${dataSearch}${textAuth}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataTagByCustomer(contactId: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `contactId=${contactId}${textAuth}`;
        const url = environment.api + `get-data-tagging/getDataTagByCustomer?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    updateTag(data: any): Observable<any[]> {
        return this.http.post<any>(environment.api + 'get-data-tagging/updateTag', data,
            {
                headers: {
                    'Content-type': 'application/json'
                }
            });
    }

}
