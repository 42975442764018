import { Component, Inject, TemplateRef, ViewChild, HostListener } from '@angular/core';
import { EventMessage, EventType, InteractionType } from '@azure/msal-browser';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG, MsalBroadcastService } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';


declare var $: any;
const GRAPH_ENDPOINT = environment.SSOLogin.graph_endpoint;
@Component({
  selector: 'app-vertical-navigation',
  templateUrl: './vertical-navigation.component.html'
})
export class VerticalNavigationComponent {
  private readonly _destroying$ = new Subject<void>();
  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private http: HttpClient,
    private authService: MsalService,
    private broadcastService: MsalBroadcastService) {
  }
  currentUser: any;

  ngOnInit() {
    this.getProfile();
    this.getAccessToken();
    
  }
  ngOnDestroy(): void {
		this._destroying$.next(undefined);
		this._destroying$.complete();
	}
  get notifications() {
    return JSON.parse(localStorage.getItem('notifications') || '[]');
  }

  getAccessToken(){
    this.broadcastService.msalSubject$
		.pipe(
			filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
			takeUntil(this._destroying$)
		)
		.subscribe((result: EventMessage) => {
      localStorage.setItem('accessToken', JSON.stringify(result.payload));
		});
  }

  

  getProfile() {
    this.http.get(GRAPH_ENDPOINT)
      .subscribe(profile => {
        this.currentUser = profile
        localStorage.setItem('currentUser', JSON.stringify(profile));
      });
  }

  logout() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.authService.logoutPopup({
        postLogoutRedirectUri: "/",
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect({
        postLogoutRedirectUri: "/",
      });
    }
    localStorage.removeItem('currentUser');
    localStorage.removeItem('accessToken');
  }
}
