<div id="service-pattern-section" class="collapse show">
  <div class="row mb-3 g-1">
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-5 control-label col-form-label">Last Visit</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.LASTRODATE | date:'d-MMM-yyyy'}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-5 control-label col-form-label">Last RO Mileage</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.LASTMILEAGE}} KM" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-3 col-lg-3 col-md-5 control-label col-form-label">Center</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.LASTVISITSVCCTR}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-2 col-lg-3 col-md-5 control-label col-form-label">SA</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.LASTVISITSA}}" disabled>
      </div>
    </div>
  </div>

  <div class="row mb-3 g-1">
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-5 control-label col-form-label">Avg Spending/RO</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.AVGSPENDING}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-5 control-label col-form-label">Last yr spending</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.LAST12MONTHSSPENDING}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-5 control-label col-form-label">Avg visit/yr</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.AVGVISIT}}" disabled>
      </div>
    </div>
  </div>

  <div class="row mb-3 g-1">
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-5 control-label col-form-label">Last Yr Mileage</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.LASTYEARMILEAGE}} KM" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-5 control-label col-form-label">Avg Mileage/Yr</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.AVGYEARMILEAGE}} KM" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 form-group row">
      <div class="col-auto button-end">
        <button type="button" class="btn btn-secondary" (click)="openModal(contentInsuranceClaim)">Ins. Claim</button>
      </div>
    </div>
    <div class="col-xl-3 col-lg-6 col-md-6 col-sm-12 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-5 control-label col-form-label">Total Visit</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.TOTALVISIT || 0}}" disabled>
      </div>
    </div>
  </div>
</div>

<!-- model Ins. Claim -->
<ng-template #contentInsuranceClaim let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Parts Status Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTableInsuranceClaim" [columnName]="columnInsuranceClaim" [columnLimit]="10"></app-table-show-data>
  </div>
</ng-template>