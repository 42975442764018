<!-- ============================================================== -->
<!-- toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-left mr-auto">

</ul>
<!-- ============================================================== -->
<!-- Right side toggle and nav items -->
<!-- ============================================================== -->
<ul class="navbar-nav float-right">
  <!-- ============================================================== -->
  <!-- Comment -->
  <!-- ============================================================== -->
  <li class="nav-item dropdown" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link dropdown-toggle waves-effect waves-dark" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i class="mdi mdi-bell font-24"></i>
      <div class="notify" *ngIf="notifications.length >0"><span class="point"></span></div>
    </a>
    <div class="dropdown-menu dropdown-menu-right mailbox" ngbDropdownMenu *ngIf="notifications.length >0">
      <span class="with-arrow">
        <span class="bg-primary"></span>
      </span>
      <ul class="list-style-none  w-100">
        <li>
          <div class="message-center notifications" [perfectScrollbar]="">
            <!-- Message -->
            <div class="message-item" *ngFor="let notification of notifications">
              <span class="mail-contnet">
                <h5 class="message-title">{{notification.title}}</h5>
                <span class="mail-desc" [innerHTML]="notification.subject | safeHtml"></span>
              </span>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </li>
  <!-- ============================================================== -->
  <!-- End Comment -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
  <li class="nav-item" ngbDropdown placement="bottom-right">
    <a ngbDropdownToggle class="nav-link text-muted waves-effect waves-dark pro-pic" href="javascript:void(0)"
      data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <img src="assets/images/users/1.jpg" alt="user" class="rounded-circle" width="31">
    </a>
    <div class="dropdown-menu-right user-dd" ngbDropdownMenu>
      <span class="with-arrow">
        <span class="bg-primary"></span>
      </span>
      <div class="d-flex no-block align-items-center p-15 bg-primary text-white m-b-10">
        <div class="">
          <img src="assets/images/users/1.jpg" alt="user" class="img-circle" width="60">
        </div>
        <div class="m-l-10">
          <h4 class="m-b-0">{{currentUser?.displayName}}</h4>
          <p class=" m-b-0">{{currentUser?.mail}}</p>
        </div>
      </div>
      <a class="dropdown-item" (click)="logout()">
        <i class="fa fa-power-off m-r-5 m-l-5"></i> Logout</a>
    </div>
  </li>
  <li class="nav-item">
    <a class="nav-link"><span class="d-none d-md-block">{{currentUser?.displayName}}</span></a>
  </li>
  <!-- ============================================================== -->
  <!-- User profile and search -->
  <!-- ============================================================== -->
</ul>


