<div class="row mb-3">
  <div class="col-lg-12">
    <form class="w-20 m-b-30">
      <input #search type='text' class="form-control" placeholder="Search" aria-label="Search"/>
    </form>
    <ngx-datatable class='material striped' [columnMode]="'force'" [headerHeight]="50" [footerHeight]="50"
      [rowHeight]="'auto'" [limit]="columnLimit" [rows]='rows' [columns]='columnName'>
    </ngx-datatable>
  </div>
</div>
