<div class="row">
  <div class="col-4"></div>
  <div class="col-4">
    <div class="card">
      <div class="card-body">
        <h4 class="card-title">Search Page</h4>
        <form class="m-t-30" [formGroup]="formGroup" novalidate (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="inputVIN">VIN</label>
            <input
              type="text"
              class="form-control"
              formControlName="inputVIN"
              id="inputVIN"
              aria-describedby=""
              placeholder=""
              oninput="this.value = this.value.toUpperCase()"
            />
          </div>

          <label for="exampleInputPassword1">Or</label>

          <div class="form-group m-t-5">
            <label for="inputLicense">License #</label>
            <input
              type="text"
              class="form-control"
              formControlName="inputLicense"
              id="inputLicense"
              placeholder=""
              oninput="this.value = this.value.toUpperCase()"
            />
          </div>

          <button class="btn btn-block btn-lg btn-info m-t-30" type="submit">Search</button>
        </form>
      </div>
    </div>
  </div>
  <div class="col-4"></div>
</div>
