import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  CommonModule
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
//import { AgmCoreModule } from '@agm/core';
import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { ToastrModule } from 'ngx-toastr';
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';

import { VerticalNavigationComponent } from './shared/vertical-header/vertical-navigation.component';
import { VerticalSidebarComponent } from './shared/vertical-sidebar/vertical-sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { HorizontalNavigationComponent } from './shared/horizontal-header/horizontal-navigation.component';
import { HorizontalSidebarComponent } from './shared/horizontal-sidebar/horizontal-sidebar.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NgxDatatableModule } from "@swimlane/ngx-datatable";


import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { SearchComponent } from './screens/search/search.component';
import { LoginPageComponent } from './screens/login-page/login-page.component';
import { RedirectChromeComponent } from './screens/redirect-chrome/redirect-chrome.component';
import { environment } from '../environments/environment';
import { NotifierModule, NotifierOptions, NotifierService } from 'angular-notifier';
import { DashboardComponent } from './screens/dashboard/dashboard.component';
import { DashboardVehicleComponent } from './components/dashboard-vehicle/dashboard-vehicle.component';
import { DashboardServicePatternComponent } from './components/dashboard-service-pattern/dashboard-service-pattern.component';
import { DashboardCustomerComponent } from './components/dashboard-customer/dashboard-customer.component';
import { DashboardCorporateCustomerComponent } from './components/dashboard-corporate-customer/dashboard-corporate-customer.component';
import { DashboardTaggingsComponent } from './components/dashboard-taggings/dashboard-taggings.component';
import { DashboardRfmScoreComponent } from './components/dashboard-rfm-score/dashboard-rfm-score.component';
import { DashboardServiceSuggestionComponent } from './components/dashboard-service-suggestion/dashboard-service-suggestion.component';
import { DashboardFootprintComponent } from './components/dashboard-footprint/dashboard-footprint.component';
import { DashboardQuotationComponent } from './components/dashboard-quotation/dashboard-quotation.component';
import { DashboardJobsTableComponent } from './components/dashboard-jobs-table/dashboard-jobs-table.component';
import { TableShowDataComponent } from './shared/table-show-data/table-show-data.component';
import { Safe } from './shared/pipe/safe';
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.SSOLogin.clientId,
      authority: environment.SSOLogin.authority,
      redirectUri:  window.location.origin
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.SSOLogin.graph_endpoint, ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

const customNotifierOptions: NotifierOptions = {
  position: {
      horizontal: {
          position: 'middle',
          distance: 12
      },
      vertical: {
          position: 'top',
          distance: 12,
          gap: 10
      }
  }
};

// Select some icons (use an object, not an array)


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    VerticalNavigationComponent,
    BreadcrumbComponent,
    VerticalSidebarComponent,
    HorizontalNavigationComponent,
    HorizontalSidebarComponent,
    SearchComponent,
    LoginPageComponent,
    RedirectChromeComponent,
    DashboardComponent,
    DashboardVehicleComponent,
    DashboardServicePatternComponent,
    DashboardCustomerComponent,
    DashboardCorporateCustomerComponent,
    DashboardTaggingsComponent,
    DashboardRfmScoreComponent,
    DashboardServiceSuggestionComponent,
    DashboardFootprintComponent,
    DashboardQuotationComponent,
    DashboardJobsTableComponent,
    TableShowDataComponent,
    Safe
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ToastrModule.forRoot(),
    FeatherModule.pick(allIcons),
    ReactiveFormsModule,
    DataTablesModule,
    HttpClientModule,
    NgbModule,
    FeatherModule,
    Ng2SearchPipeModule,
    AppRoutingModule,
    PerfectScrollbarModule,
    NgMultiSelectDropDownModule.forRoot(),
    NotifierModule.withConfig(customNotifierOptions),
    NgxDatatableModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MsalModule
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    NotifierService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
