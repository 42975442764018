import { Component, Input, OnInit, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { getDataTable } from 'src/app/services/getDataDashboard/getDataTable.service';
import { ShowNotifierService } from 'src/app/services/showNotifierService/showNotifierService.service';
import * as _ from "lodash";
import * as moment from 'moment';
import { Search } from 'src/app/model/search';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-dashboard-service-suggestion',
  templateUrl: './dashboard-service-suggestion.component.html',
  styleUrls: ['./dashboard-service-suggestion.component.scss']
})
export class DashboardServiceSuggestionComponent implements OnInit {
  @Input() vehicleInfo: any;
  @ViewChild('brochureURL', { static: true }) brochureURL!: TemplateRef<any>;
  @ViewChild('videoURL', { static: true }) videoURL!: TemplateRef<any>;
  isLoadingModal: boolean = true;
  dataTableServiceSuggestion: any;
  columnServiceSuggestion: any;
  textSearch!: Search;

  constructor(
    private getDataTable: getDataTable,
    private showNotifierService: ShowNotifierService,
    private activatedRoute: ActivatedRoute
  ) {}
  
  ngOnInit(): void {
    this.columnServiceSuggestion = [
      { name: 'Service Item', prop: 'SERVICEITEMDSC' },
      { name: 'Service Item Comment', prop: 'SERVICEITEMCOMMENT' },
      { name: 'Last Purchase Date', prop: 'LASTPURCHASEDATE' },
      { name: 'Brochure URL', prop: 'BORCHUREURL'},
      { name: 'Video URL', prop: 'VIDEOURL'}
    ];
    this.textSearch = this.getTextSearchVehicle();
    this.getDataServiceSuggestion();
  }

  getTextSearchVehicle() {
    return JSON.parse(localStorage.getItem('search_vehicle') || '{}');
  }

  getTextSearchInParam() {
    let data:any;
    if(_.isEmpty(this.activatedRoute.snapshot.queryParams)){
      return localStorage.getItem('search_vehicle') || '{}';
    }else{
      data = {
        "inputVIN": this.activatedRoute.snapshot.queryParams.ChassisNo,
        "inputLicense": null,
        "serviceCenter": this.activatedRoute.snapshot.queryParams.ServiceCenterCode,
      }
      return JSON.stringify(data);
    }
  }

  getDataServiceSuggestion() {
    this.getDataTable.getDataServiceSuggestion(String(this.vehicleInfo.CHSNO), String(this.textSearch.serviceCenter)).subscribe((response: any[]) => {
      let dataReturn: any[] = [];
      response.forEach((element: any) => {
        if (element.LASTPURCHASEDATE != null) {
          element.LASTPURCHASEDATE = `${moment(new Date(element.LASTPURCHASEDATE)).format("d-MMM-yyyy")}`;
        } else {
          element.LASTPURCHASEDATE = '';
        }
        dataReturn.push(element);
      });      
      this.dataTableServiceSuggestion = dataReturn;
      this.isLoadingModal = false;
    }, (error: any) => {
      this.isLoadingModal = false;
    });
  }
}
