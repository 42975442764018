import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, } from 'rxjs/operators';
import { isEmpty } from 'lodash';
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';


@Injectable({
    providedIn: 'root'
})
export class getDataJobTableSection {

    constructor(private http: HttpClient, private getTokenService: getTokenService) { }

    getDataJobTable(pi_chassis_no: String): Observable<any[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${pi_chassis_no}${textAuth}`;
        const url = environment.api + `get-job-table/getDataJobTable?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

}
