import { Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as $ from "jquery";
import * as _ from 'lodash';
import { getDataTable } from 'src/app/services/getDataDashboard/getDataTable.service';

@Component({
  selector: 'app-dashboard-taggings',
  templateUrl: './dashboard-taggings.component.html',
  styleUrls: ['./dashboard-taggings.component.scss']
})
export class DashboardTaggingsComponent implements OnInit {
  @ViewChild('div') div!: ElementRef;
  @Input() vehicleInfo: any;
  @Input() customerInfo: any;
  dataTaggingCategory: any;
  dataTagByCustomer: any;
  dataTagByCategory: any;
  isLoadingModalTaggingCategory: boolean = true;
  isLoadingModal: boolean = true;
  isLoadingModalTagByCategory: boolean | undefined;
  constructor(private modalService: NgbModal,
    private renderer: Renderer2,
    private elem: ElementRef,
    private getDataTable: getDataTable,
  ) { }

  ngOnInit(): void {
    this.getDataTaggingCategory();
    this.getDataTagByCustomer();
  }

  addElement() {
    let elements = document.body.querySelectorAll('input[type=checkbox]:checked');
    this.updateTag(String(_.map(elements, 'value').join(', ')));
    this.modalService.dismissAll();
  }

  onChange(event: any, dataTag: any) {
    let tagIdCanSelectOne = ['d8cdddbe-a05c-eb11-a811-000d3aa32c57', 'dacdddbe-a05c-eb11-a811-000d3aa32c57', 'dccdddbe-a05c-eb11-a811-000d3aa32c57'];
    if (tagIdCanSelectOne.includes(dataTag)) {      
      document.body.querySelectorAll('[class="'+dataTag+'"]').forEach((el: any) => el.checked = false);
      event.srcElement.checked = true;
    }
  }



  isSelected(dch_optionvalue: any) {
    let arrTagSelected = _.map(this.dataTagByCustomer, 'Value');
    return arrTagSelected.includes(dch_optionvalue);
  }

  openModal(content: string) {
    this.modalService.open(content, { centered: true });
  }

  getDataTaggingCategory() {
    this.getDataTable.getDataTaggingCategory().subscribe((response: any[]) => {
      this.dataTaggingCategory = response;
      this.isLoadingModalTaggingCategory = false;
    }, (error: any) => {
      this.isLoadingModalTaggingCategory = false;
    });
  }

  getDataTagByCategory(event: any, categoryId: string) {
    document.querySelector('#help-text')?.remove();
    let elements = document.body.querySelectorAll('.active');
    if (elements !== null) {
      for (let i = 0; i < elements.length; i++) {
        elements[i].classList.remove('active');
      }
    }
    event.srcElement.classList.add("active");
    this.isLoadingModalTagByCategory = true;
    this.getDataTable.getDataTagByCategory(categoryId).subscribe((response: any[]) => {
      this.dataTagByCategory = response;
      this.isLoadingModalTagByCategory = false;
    }, (error: any) => {
      this.isLoadingModalTagByCategory = false;
    });
  }

  getDataTagByCustomer() {
    this.getDataTable.getDataTagByCustomer(String(this.customerInfo.contactid)).subscribe((response: any[]) => {
      this.dataTagByCustomer = response;
      this.isLoadingModal = false;
    }, (error: any) => {
      this.isLoadingModal = false;
    });
  }

  updateTag(optionValue: string) {
    let data = {
      "TargetID": this.customerInfo.contactid,
      "TagOptionValue": optionValue,
    };
    this.isLoadingModal = true;
    this.getDataTable.updateTag(data).subscribe((response: any[]) => {
      this.getDataTagByCustomer();
    }, (error: any) => {

    });
  }

}
