import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-redirect-chrome',
  templateUrl: './redirect-chrome.component.html',
  styleUrls: ['./redirect-chrome.component.scss']
})
export class RedirectChromeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
