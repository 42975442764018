import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { catchError, map, } from 'rxjs/operators';
import { Search } from 'src/app/model/search';
import { OS_ROAndDataRO_JOB, PartsStatus, UpcomingAppt, VehicleInfo } from 'src/app/model/vehicle-section';
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';

@Injectable({
    providedIn: 'root'
})
export class getDataVehicleSection {

    constructor(private http: HttpClient, private getTokenService: getTokenService) { }

    getVehicleInfo(textSearch: Search): Observable<VehicleInfo> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${textSearch.inputVIN}&pi_license_no=${textSearch.inputLicense}${textAuth}`;
        const url = environment.api + `vehicle-section/getVehicleInfo?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataOS_ROAndDataRO_JOB(textSearch: String): Observable<OS_ROAndDataRO_JOB[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${textSearch}${textAuth}`;
        const url = environment.api + `vehicle-section/getDataOSRO?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataUpcomingAppt(textSearch: String): Observable<UpcomingAppt[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${textSearch}${textAuth}`;
        const url = environment.api + `vehicle-section/getDataUpcomingAppt?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

    getDataPartsStatus(textSearch: String): Observable<PartsStatus[]> {
        let textAuth = this.getTokenService.getTextSearchAccessToken();
        const dataSearch = `pi_chassis_no=${textSearch}${textAuth}`;
        const url = environment.api + `vehicle-section/getDataPartsStatus?${dataSearch}`;
        return this.http.get<any>(url).pipe(map((res: any) => res), catchError((error: any) => {
            return throwError(error);
        }))
    }

}
