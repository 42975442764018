import { DomSanitizer } from '@angular/platform-browser';
import { Pipe } from '@angular/core';

// tslint:disable-next-line: use-pipe-transform-interface
@Pipe({ name: 'safeHtml' })
export class Safe {
    constructor(private sanitizer: DomSanitizer) { }

    transform(style:any) {
        return this.sanitizer.bypassSecurityTrustHtml(style);

    }
}
