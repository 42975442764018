<div id="customer-section" class="collapse show">
  <div class="row mb-3 g-1">
    <div class="col-xl-2 col-lg-4 col-md-4 form-group row">
      <div class="col-auto" ngbTooltip="{{customerInfo?.fullname}} {{customerInfo?.dch_chinesefullname}}">
        <input type="text" class="form-control" value="{{customerInfo?.fullname}} {{customerInfo?.dch_chinesefullname}}"
          disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-5 form-group row">
      <label class="col-xl-3 col-lg-3 col-md-3 control-label col-form-label">Type</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{customerInfo?.dch_customertype}}" disabled>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-3 form-group row">
      <div class="col-auto">
        <button type="button" class="btn btn-secondary"
          (click)="openModal(contentVehicleDetail, 'contentVehicleDetail')">No. of Cars</button>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-5 form-group row">
      <label class="col-xl-3 col-lg-3 col-md-3 control-label col-form-label">VIP:</label>
      <div class="col-auto" ngbTooltip="{{customerInfo?.dch_viptype}}">
        <input type="text" class="form-control" value="{{customerInfo?.dch_viptype}}" disabled>
      </div>
    </div>
    <div class="col-xl-1 col-lg-2 col-md-2 form-group row">
      <div class="col-auto">
        <button type="button" class="btn btn-secondary"
          (click)="openModal(contentComplaint, 'contentComplaint')">Complaint</button>
      </div>
    </div>

  </div>
  <div class="row mb-3 g-1">
    <div class="col-xl-40 col-lg-6 col-md-12 form-group row">
      <label class="col-xl-2 col-lg-2 col-md-2 control-label col-form-label">Age Group:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{customerInfo?.dch_hh_agerange}}" disabled>
      </div>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{customerInfo?.dch_hh_maritalstatus}}" disabled>
      </div>
    </div>
    <div class="col-xl-40 col-lg-6 col-md-12 form-group row">
      <label class="col-xl-2 col-lg-2 col-md-2 control-label col-form-label">Income:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{customerInfo?.dch_hh_monthlyincome}}" disabled>
      </div>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{customerInfo?.dch_hh_occupation}}" disabled>
      </div>
    </div>
    <div class="col-xl-2 col-lg-4 col-md-6 form-group row pr-0"> 
      <label class="col-auto col-md-3 col-form-label">Edu:</label>
      <div class="col-auto p-0">
        <input type="text" class="form-control" value="{{customerInfo?.dch_hh_education}}" disabled>
      </div>
    </div>
   
  </div>
  <div class="row mb-3 g-1">
    <div class="col-xl-3 col-lg-4 col-md-5 form-group row">
      <label class="col-xl-3 col-lg-3 col-md-3 control-label col-form-label">Mobile:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{customerInfo?.mobilephone}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-6 form-group row">
      <label class="col-xl-3 col-lg-3 col-md-4 control-label col-form-label">Officel Tel:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.OFFICEPHONE}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-5 form-group row">
      <label class="col-xl-3 col-lg-3 col-md-3 control-label col-form-label">Email:</label>
      <div class="col-auto" ngbTooltip="{{customerInfo?.emailaddress1}}">
        <input type="text" class="form-control" value="{{customerInfo?.emailaddress1}}" disabled>
      </div>
    </div>
   
    <div class="col-xl-40 col-lg-6 col-md-9 form-group row">
      <label class="col-xl-2 col-lg-2 col-md-2 control-label col-form-label">Address:</label>
      <div class="col-auto" ngbTooltip="{{customerInfo?.address1_line1}} {{customerInfo?.address1_line2}} {{customerInfo?.address1_line3}} {{customerInfo?.dch_address1_line4}} {{customerInfo?.dch_districtcode}} {{customerInfo?.dch_area}} {{customerInfo?.dch_country}}">
        <input type="text" class="form-control" value="{{customerInfo?.address1_line1}} {{customerInfo?.address1_line2}} {{customerInfo?.address1_line3}} {{customerInfo?.dch_address1_line4}} {{customerInfo?.dch_districtcode}} {{customerInfo?.dch_area}} {{customerInfo?.dch_country}}" disabled>
      </div>
      <div class="col-auto" ngbTooltip="{{customerInfo?.dch_chineseaddress1}} {{customerInfo?.dch_chineseaddress2}} {{customerInfo?.dch_chineseaddress3}} {{customerInfo?.dch_chineseaddress4}}">
        <input type="text" class="form-control" value="{{customerInfo?.dch_chineseaddress1}} {{customerInfo?.dch_chineseaddress2}} {{customerInfo?.dch_chineseaddress3}} {{customerInfo?.dch_chineseaddress4}}" disabled>
      </div>
    </div>
    <div class="col-xl-2 col-lg-2 col-md-3 form-group row">
      <div class="col-auto">
        <button type="button" class="btn btn-secondary"
        (click)="openModal(contentOptInOutDetail, 'contentOptInOutDetail')">Opt In/Out</button>
      </div>
    </div>
  </div>
  <div class="row mb-3 g-1">
    <div class="col-xl-3 col-lg-4 col-md-7 form-group row">
      <label class="col-xl-4 col-lg-4 col-md-5 control-label col-form-label">Motor Club:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{customerInfo?.dch_dchmemberno}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-5 form-group row">
      <label class="col-xl-3 col-lg-3 col-md-3 control-label col-form-label">Class:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.MEMBERCLASS}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-5 form-group row">
      <label class="col-xl-2 col-lg-2 col-md-2 control-label col-form-label">BP:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.VALIDPOINT}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-7 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-6 control-label col-form-label">Birthday Month:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{customerInfo?.birthdate | date:'MMM'}}" disabled>
      </div>
    </div>
    <div class="col-xl-3 col-lg-4 col-md-7 form-group row">
      <label class="col-xl-5 col-lg-5 col-md-6 control-label col-form-label">Previous DCH Car:</label>
      <div class="col-auto">
        <input type="text" class="form-control" value="{{vehicleInfo?.PREVIOUSDCHCAROWNER}}" disabled>
      </div>
    </div>
    <div class="col-xl-4 col-lg-4 col-md-8 form-group row">
      <div class="col-auto" ngbTooltip="Credit Account Number">
        <input type="text" class="form-control" value="{{customerInfo?.dch_creditaccountno}}" disabled>
      </div>
      <div class="col-auto" ngbTooltip="Credit Account Status">
        <input type="text" class="form-control" value="{{customerInfo?.dch_creditaccountstatus}}" disabled>
      </div>
    </div>   
  </div>
  <div class="row mb-3 g-1">
    <div class="col-xl-12 col-lg-12 col-md-12 form-group row">
      <div class="col-6" ngbTooltip="Contact 1 Email">
        <input type="text" class="form-control" value="{{vehicleInfo?.LASTROCONTACTPERSON1}} {{vehicleInfo?.LASTROCONTACTPHONE1}} {{vehicleInfo?.LASTROCONTACTEMAIL}} {{vehicleInfo?.LASTROCONTACTFAX}}"
        disabled>
      </div>
      <div class="col-6" ngbTooltip="Contact 2 Email">
        <input type="text" class="form-control" value="{{vehicleInfo?.LASTROCONTACTPERSON2}} {{vehicleInfo?.LASTROCONTACTPHONE2}} {{vehicleInfo?.LASTROCONTACTEMAIL}} {{vehicleInfo?.LASTROCONTACTFAX}}"
        disabled>
      </div>
    </div>   
  </div>
</div>

<!-- model No. of Cars -->
<ng-template #contentVehicleDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Vehicle Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTableVehicleDetail" [columnName]="columnVehicleDetail"
      [columnLimit]="10"></app-table-show-data>
  </div>
</ng-template>

<!-- model Opt In/Out -->
<ng-template #contentOptInOutDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Opt In/Out Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTableOptInOutDetail"
      [columnName]="columnOptInOutDetail" [columnLimit]="10"></app-table-show-data>
  </div>
</ng-template>

<!-- model Complaint -->
<ng-template #contentComplaint let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Complaint Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="min-height-500" *ngIf="isLoadingModal">
      <div class="dark_overlay-modal">
        <div class="loader"></div>
      </div>
    </div>
    <app-table-show-data *ngIf="!isLoadingModal" [dataTable]="dataTableComplaint" [columnName]="columnComplaint"
      [columnLimit]="10"></app-table-show-data>
  </div>
</ng-template>

<!-- modal Complaint Remark -->
<ng-template #contentComplaintRemarkDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Complaint Remark Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ complaintRemarkDetail }}</p>
  </div>
</ng-template>

<!-- modal Remedy Remark -->
<ng-template #contentRemedyRemarkDetail let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Remedy Remark Detail</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{ remedyRemarkDetail }}</p>
  </div>
</ng-template>

<!-- custom column for complaint table -->
<ng-template #complaintRemarks let-row="row" let-rowIndex="rowIndex" let-columnValue="columnValue">
  <div
    container="body"
    class="text-overflow"
    style="cursor: pointer;"
    (click)="openModal(contentComplaintRemarkDetail, 'complaintRemarkDetailModal', row.COMPLAINTREMARKS)">{{row.COMPLAINTREMARKS}}</div>
</ng-template>
<ng-template #remedyRemarks let-row="row" let-rowIndex="rowIndex" let-columnValue="columnValue">
  <div
    container="body"
    class="text-overflow"
    style="cursor: pointer;"
    (click)="openModal(contentRemedyRemarkDetail, 'remedyRemarkDetailModal', row.REMEDYREMARKS)">{{row.REMEDYREMARKS}}</div>
</ng-template>
