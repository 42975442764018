<div class="auth-wrapper d-flex no-block justify-content-center align-items-center flex-container"
  style="background:url(assets/images/background/login-register.jpg) no-repeat center center;">
  <div class="auth-box m-3 m-md-0 w-40em-ie">
    <div id="loginform">
      <div class="row">
        <div class="col-12">
          <form class="form-horizontal m-t-20" id="loginform">
            <div class="form-group text-center">
              <div class="col-xs-12 p-b-20">
                <button class="btn btn-block btn-lg btn-info"(click)="login()">Sign in with
                  SSO</button>
              </div>
            </div>
            <div class="form-group m-b-0 m-t-10">
              <div class="col-sm-12 text-center">
                Don't have an account? <a href="" class="text-info m-l-5"><b>Sign Up</b></a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="dark_overlay" *ngIf="isLoading">
  <div class="loader"></div>
</div>