import { Component, Input, OnInit } from '@angular/core';
import { VehicleInfo } from 'src/app/model/vehicle-section';

@Component({
  selector: 'app-dashboard-rfm-score',
  templateUrl: './dashboard-rfm-score.component.html',
  styleUrls: ['./dashboard-rfm-score.component.scss']
})
export class DashboardRfmScoreComponent implements OnInit {
  @Input() vehicleInfo!: VehicleInfo;

  constructor() { }

  ngOnInit(): void {
  }

}
