import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { getDataServicePatternSection } from 'src/app/services/getDataDashboard/getDataServicePatternSection.service';
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';

declare var require: any;
@Component({
  selector: 'app-dashboard-service-pattern',
  templateUrl: './dashboard-service-pattern.component.html',
  styleUrls: ['./dashboard-service-pattern.component.scss']
})
export class DashboardServicePatternComponent implements OnInit {

  dataTableInsuranceClaim!: any;
  columnInsuranceClaim: any = [
    { name: 'R/O No.', prop: 'RONO', width: 20 },
    { name: 'R/O Date', prop: 'RODATE', width: 20 },
    { name: 'Job Code', prop: 'JOBCODE', width: 80 },
    { name: 'Job Description', prop: 'JOBDSC', width: 300 },
    { name: 'Job Price', prop: 'JOBPRICE', width: 20 },
  ];

  @Input() vehicleInfo: any;

  @ViewChild(DashboardServicePatternComponent) table: DashboardServicePatternComponent | any;
  chassisNo!: string;
  isLoadingModal!: boolean;

  constructor(
    private modalService: NgbModal,
    private getDataServicePatternSection: getDataServicePatternSection,
    private accessTokenService: getTokenService
  ) {}

  ngOnInit(): void {
  }

  openModal(content: string) {
    this.chassisNo = this.vehicleInfo.CHSNO;
    this.isLoadingModal = true;
    this.getDataInsuranceClaim();
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  async getDataInsuranceClaim() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.getDataServicePatternSection.getDataInsuranceClaim(String(this.chassisNo)).subscribe((response: any[]) => {
      let dataReturn: any[] = [];
      response.forEach((element: any) => {
        element.RODATE = `${moment(new Date(element.RODATE)).format("d-MMM-yyyy")}`;
        dataReturn.push(element);
      });
      this.dataTableInsuranceClaim = dataReturn;
      this.isLoadingModal = false;
    }, (error: any) => { });
  }
}
