import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { SearchComponent } from './screens/search/search.component';
import { LoginPageComponent } from './screens/login-page/login-page.component';
import { RedirectChromeComponent } from './screens/redirect-chrome/redirect-chrome.component';
import { FullComponent } from './layouts/full/full.component';
import { DashboardComponent } from './screens/dashboard/dashboard.component';

const routes: Routes = [
  {
    canActivate: [MsalGuard],
    path: 'page',
    component: FullComponent,
    children: [
      {
        path: 'dashboard',
        component: DashboardComponent
      },
      {
        path: 'search',
        component: SearchComponent
      }
    ]
  },
  {
    path: '',
    component: LoginPageComponent
  },
  {
    path: 'redirect-chrome',
    component: RedirectChromeComponent
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: false,
    initialNavigation: !isIframe ? 'enabled' : 'disabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
