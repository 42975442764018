import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Search } from 'src/app/model/search';
import { getDataVehicleSection } from 'src/app/services/getDataDashboard/getDataVehicleSection.service';
import * as _ from "lodash";
import { OS_ROAndDataRO_JOB, PartsStatus, UpcomingAppt, VehicleInfo } from 'src/app/model/vehicle-section';
import * as moment from 'moment';
import { getTokenService } from 'src/app/services/getToken/getTokenService.service';
@Component({
  selector: 'app-dashboard-vehicle',
  templateUrl: './dashboard-vehicle.component.html',
  styleUrls: ['./dashboard-vehicle.component.scss']
})
export class DashboardVehicleComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private getDataVehicleSection: getDataVehicleSection,
    private accessTokenService: getTokenService
  ) { }
  dataTableOutstanding: any;
  columnOutstanding: any = [
    { name: 'R/O No.', prop: 'RONO', width: 20 },
    { name: 'R/O Date', prop: 'RODATE', width: 20 },
    { name: 'Status', prop: 'STATUS', width: 10 },
    { name: 'Job Code', prop: 'JOBCODE', width: 20 },
    { name: 'Job Type', prop: 'JOBTYPE', width: 20 },
    { name: 'Job Description', prop: 'JOBDSC', width: 300 },
    { name: 'Part Status', prop: 'PARTSTS', width: 20 },
  ];

  dataTablePartsStatus: any;
  columnPartsStatus: any = [
    { name: 'R/O No.', prop: 'RONO', width: 20 },
    { name: 'R/O Date', prop: 'RODATE', width: 20 },
    { name: 'Job Code', prop: 'JOBCODE', width: 20 },
    { name: 'Job Type', prop: 'JOBTYPE', width: 20 },
    { name: 'Job Description', prop: 'JOBDSC', width: 300 },
    { name: 'Part Status', prop: 'PARTSTS', width: 20 },
  ];

  dataTableAppointment: any[] = [];
  columnAppointment: any = [
    { name: 'Appt. No.', prop: 'APPTNO' },
    { name: 'Appt. DateTime', prop: 'APPTDATETIME' },
    { name: 'Job Code', prop: 'JOBCODE' },
  ]
  @Input() vehicleInfo: any;
  textSearch: Search = JSON.parse(localStorage.getItem('search_vehicle') || '{}');
  chassisNo: string | undefined;
  isLoading: boolean = false;
  isLoadingModal: boolean = true;

  ngOnInit(): void {
  }

  openModal(content: string, nameModal: string) {
    this.chassisNo = this.vehicleInfo.CHSNO;
    switch (nameModal) {
      case 'contentOutstanding':
        if (_.isEmpty(this.dataTableOutstanding)) {
          this.isLoadingModal = true;
          this.getDataOS_ROAndDataRO_JOB();
        }
        break;
      case 'contentAppointment':
        if (_.isEmpty(this.dataTableAppointment)) {
          this.isLoadingModal = true;
          this.getDataUpcomingAppt();
        }
        break;
      case 'contentPartsStatus':
        if (_.isEmpty(this.dataTablePartsStatus)) {
          this.isLoadingModal = true;
          this.getDataPartsStatus();
        }
        break;
      default:
        break;
    }
    this.modalService.open(content, { size: 'xl', centered: true });
  }

  async getDataOS_ROAndDataRO_JOB() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.getDataVehicleSection.getDataOS_ROAndDataRO_JOB(String(this.chassisNo)).subscribe((response: OS_ROAndDataRO_JOB[]) => {
      let dataReturn: OS_ROAndDataRO_JOB[] = [];
      response.forEach((element: OS_ROAndDataRO_JOB) => {
        element.RODATE = `${moment(new Date(element.RODATE)).format("d-MMM-yyyy")}`;
        dataReturn.push(element);
      });
      this.dataTableOutstanding = dataReturn;
      this.isLoadingModal = false;
    }, (error: any) => { });
  }

  async getDataUpcomingAppt() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.getDataVehicleSection.getDataUpcomingAppt(String(this.chassisNo))
      .subscribe((response: UpcomingAppt[]) => {
        this.isLoadingModal = false;
        this.dataTableAppointment = response;
        this.dataTableAppointment = Object
          .values(
            this.dataTableAppointment
              .reduce((data, v) => {
                const key = `${v['APPTNO']}-${v['APPTDATE']}`;

                if (!data[key]) {
                  data[key] = [v];
                } else {
                  data[key][0]['JOBCODE'] += `, ${v['JOBCODE']}`;
                }

                return data;
              }, {})
          ).map((items: any) => items[0]);
      }, (error: any) => { });
  }

  async getDataPartsStatus() {
    const isValidAccessToken = this.accessTokenService.isAccessTokenValid();

    if (!isValidAccessToken) {
      await this.accessTokenService.exchangeAccessToken();
    }

    this.getDataVehicleSection.getDataPartsStatus(String(this.chassisNo)).subscribe((response: PartsStatus[]) => {
      let dataReturn: PartsStatus[] = [];
      response.forEach((element: PartsStatus) => {
        element.RODATE = `${moment(new Date(element.RODATE)).format("d-MMM-yyyy")}`;
        dataReturn.push(element);
      });
      this.dataTablePartsStatus = dataReturn;
      this.isLoadingModal = false;
    }, (error: any) => { });
  }

  displayRemainingServices(): String {
    if (!this.vehicleInfo) {
      return '';
    }

    let remainingServices = [];

    if (this.vehicleInfo.N1 && this.vehicleInfo.N1 != '0') {
      remainingServices.push('N1:' + this.vehicleInfo.N1);
    }

    if (this.vehicleInfo.SIO && this.vehicleInfo.SIO != '0') {
      remainingServices.push('SIO:' + this.vehicleInfo.SIO);
    }

    if (this.vehicleInfo.SMP && this.vehicleInfo.SMP != '0') {
      remainingServices.push('SMP:' + this.vehicleInfo.SMP);
    }

    if (this.vehicleInfo.EMP && this.vehicleInfo.EMP != '0') {
      remainingServices.push('EMP:' + this.vehicleInfo.EMP);
    }

    if (this.vehicleInfo.STP && this.vehicleInfo.STP != '0') {
      remainingServices.push('STP:' + this.vehicleInfo.STP);
    }

    return remainingServices.join(', ');
  }

}
