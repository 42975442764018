<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>SA Dashboard</title>
    <style> 
        input[type=button], input[type=submit], input[type=reset] {
          background-color: #04AA6D;
          border: none;
          color: white;
          padding: 16px 32px;
          text-decoration: none;
          margin: 4px 2px;
          cursor: pointer;
        }
        </style>
</head>
<body>
    <h3>SA Dashboard</h3>
    <p>Please click on the button to access SA Dashboard in Google Chrome</p>
    <input type="button" onclick="openURLByChrome()" value="Open SA Dashboard in Chrome">
</body>
</html>